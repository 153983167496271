// themes - our custom or/and out of the box themes
@use 'themes' as *;

// framework component themes (styles tied to theme variables)
@use '@nebular/theme/styles/globals' as *;
// @import '@nebular/auth/styles/all';
@use '@nebular/auth/styles/globals' as *;
@use '@nebular/bootstrap/styles/globals' as *;
@import '@ng-select/ng-select/themes/default.theme.css';
// ...

// global app font size
@import './font-size';

// loading progress bar theme
@import './pace.theme';

@import './widgets';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();
  @include nb-bootstrap-global();

  // loading progress bar
  @include ngx-pace-theme();
};

// @use 'themes' as *;

// @use '@nebular/theme/styles/globals' as *;

// @use 'app/@theme/styles/nipponrika.theme.scss' as *;