@import './constants.scss';

// legacy 3.6 theme
// // default the base theme
// $theme: (
//   font-main: unquote('"Pero-Regular", Helvetica, Arial, Verdana, sans-serif'),

//   font-size-xlg: 1.25rem,
//   font-size-lg: 1.125rem,
//   font-size: 1rem,
//   font-size-sm: 0.875rem,
//   font-size-xs: 0.75rem,


//   header-fg: #f7fafb,
//   header-bg: $nrk_0,

//   layout-bg: #ffffff, // #f1f5f8,

//   color-fg: #181818,

//   color-fg-heading: #181818,
//   color-fg-text:  #4b4b4b,
//   color-fg-highlight: color-fg,
//   color-white: #ffffff,

//   separator: #cdd5dc,

//   radius: 0.17rem,

//   scrollbar-bg: #e3e9ee,

//   color-primary: $nrk_1,
//   color-success: #5dcfe3, // $nrk_3, //
//   color-info: #ba7fec,
//   color-warning: #ffa36b,
//   color-danger: #ff6b83,

//   btn-secondary-bg: #edf2f5,
//   btn-secondary-border: #edf2f5,

//   actions-fg: #d3dbe5,
//   actions-bg: color-bg,

//   sidebar-bg: $nrk_0, // #e3e9ee,

//   border-color: #d5dbe0,

//   shadow: none,

//   menu-font-weight: font-weight-bolder,
//   menu-fg: #ffffff, //color-fg-text,
//   menu-bg: $nrk_0, //  #e3e9ee,
//   menu-active-fg: color-fg-heading,
//   menu-active-bg: $nrk_2, // menu-bg,
//   menu-font-size: 1.2rem,

//   menu-icon-color: #eeeeee,

//   menu-submenu-bg: #ffffff, // menu-bg,
//   menu-submenu-fg: color-fg-text,
//   menu-submenu-active-fg: color-fg-heading,
//   menu-submenu-active-bg: #ffffff, // #cdd5dc,
//   menu-submenu-active-border-color: menu-submenu-active-bg,
//   menu-submenu-active-shadow: none,
//   menu-submenu-hover-fg: menu-submenu-active-fg,
//   menu-submenu-hover-bg: $nrk_2, // menu-bg,
//   menu-submenu-item-border-width: 0, // 0.125rem,
//   menu-submenu-item-border-radius: radius,
//   menu-submenu-item-padding: 0.5rem 1rem,
//   menu-submenu-item-container-padding: 0, // 0 1.25rem,
//   menu-submenu-padding:  0.5rem,

//   btn-border-radius: btn-semi-round-border-radius,

//   btn-hero-degree: 0deg,
//   btn-hero-primary-degree: btn-hero-degree,
//   btn-hero-success-degree: btn-hero-degree,
//   btn-hero-warning-degree: btn-hero-degree,
//   btn-hero-info-degree: btn-hero-degree,
//   btn-hero-danger-degree: btn-hero-degree,
//   btn-hero-secondary-degree: btn-hero-degree,
//   btn-hero-glow-size: 0 0 20px 0,
//   btn-hero-primary-glow-size: btn-hero-glow-size,
//   btn-hero-success-glow-size: btn-hero-glow-size,
//   btn-hero-warning-glow-size: btn-hero-glow-size,
//   btn-hero-info-glow-size: btn-hero-glow-size,
//   btn-hero-danger-glow-size: btn-hero-glow-size,
//   btn-hero-secondary-glow-size: 0 0 0 0,
//   btn-hero-border-radius: btn-border-radius,

//   btn-fg: #181818,
//   btn-padding-y-lg: 0.4rem,
//   btn-padding-x-lg: 1.0rem,
//   btn-padding-y-md: 0.4rem,
//   btn-padding-x-md: 1.0rem,
//   btn-padding-y-sm: 0.4rem,
//   btn-padding-x-sm: 1.0rem,

//   card-shadow: none,
//   card-border-width: 1px,
//   card-border-color: border-color,
//   card-header-border-width: 0,

//   link-color: $nrk_3,
//   link-color-hover: lighten($nrk_3, 10%),
//   link-color-visited: link-color,

//   actions-separator: #f1f4f5,

//   modal-separator: border-color,

//   tabs-selected: color-primary,
//   tabs-selected-second-color: color-primary,
//   tabs-separator: #ebecee,


//   smart-table-header-fg: #000000,
//   smart-table-header-bg: #eeeeee,
//   smart-table-paging-bg-active: color-primary,
//   smart-table-bg-active: #f0f0f0,
//   smart-table-bg-even: #ffffff, // #f6f6f6,


//   route-tabs-selected: color-primary,

//   popover-border: color-primary,

//   footer-shadow: none,
//   footer-separator: border-color,
//   footer-fg-highlight: #2a2a2a,

//   calendar-today-item-bg: #a2b2c7,
//   calendar-active-item-bg: color-primary,
//   calendar-range-bg-in-range: #e3ecfe,
//   calendar-today-fg: color-white,

//   toastr-icon-radius: radius,

//   datepicker-border: color-primary,

//   form-control-placeholder-color: #bbbbbb,

//   progress-bar-bg: #eeeeee,
//   progress-bar-default-bg: $nrk_2,
//   progress-bar-danger-bg: $nrk_1,

//   scrollbar-width: 15px,
// );

// default the base theme
$theme: (
  color-primary-100: #FACAC9,
  color-primary-200: #F6949C,
  color-primary-300: #E45D75,
  color-primary-400: #CA345D,
  color-primary-500: #A70240,
  color-primary-600: #8F0144,
  color-primary-700: #780145,
  color-primary-800: #600041,
  color-primary-900: #50003E,
  color-success-100: #F1F9C7,
  color-success-200: #E0F493,
  color-success-300: #C0DE59,
  color-success-400: #99BD30,
  color-success-500: #689101,
  color-success-600: #557C00,
  color-success-700: #446800,
  color-success-800: #345400,
  color-success-900: #294500,
  color-info-100: #C9FBFB,
  color-info-200: #94F2F8,
  color-info-300: #5DD7EA,
  color-info-400: #35B5D5,
  color-info-500: #0088BA,
  color-info-600: #00699F,
  color-info-700: #004F85,
  color-info-800: #00386B,
  color-info-900: #002859,
  color-warning-100: #FDEDCF,
  color-warning-200: #FBD6A0,
  color-warning-300: #F5B670,
  color-warning-400: #EB974C,
  color-warning-500: #de6916,
  color-warning-600: #BE4E10,
  color-warning-700: #9F370B,
  color-warning-800: #802407,
  color-warning-900: #6A1704,
  color-danger-100: #FCE1D3,
  color-danger-200: #FABEA9,
  color-danger-300: #F0907C,
  color-danger-400: #E26559,
  color-danger-500: #d02828,
  color-danger-600: #B21D2A,
  color-danger-700: #95142A,
  color-danger-800: #780C28,
  color-danger-900: #630727,

  layout-background-color: white,

  font-family-primary: unquote('"Pero-Regular", Helvetica, Arial, Verdana, sans-serif'),
  font-family-secondary: unquote('"Pero-Regular", Helvetica, Arial, Verdana, sans-serif'),

  // layout-text-line-height: 1rem,
  layout-text-font-weight: normal,

  text-paragraph-font-size: 14px,

  text-label-font-size: text-paragraph-font-size,

  text-label-font-weight: normal,
  // text-label-color: black,
  // text-hint-color: input-basic-text-color,

  link-text-decoration: none,

  // text-subtitle-font-size: 1rem,
  // text-subtitle-2-font-size: 1rem,
  // text-paragraph-font-size: 1rem,
  // text-paragraph-2-font-size:1rem,
  // text-label-font-size: 1rem,
  // text-caption-font-size: 1rem,
  // text-caption-2-font-size: 1rem,

  // text-button-tiny-font-size:
  // text-button-small-font-size:
  // text-button-medium-font-size:
  // text-button-large-font-size:
  // text-button-giant-font-size:

  text-heading-1-font-size: 2em,
  // text-heading-2-font-size: 1rem,
  // text-heading-3-font-size: 1rem,
  // text-heading-4-font-size: 1rem,
  // text-heading-5-font-size: 1rem,
  // text-heading-6-font-size: 1rem,
  text-heading-1-font-weight: 500,
  text-heading-2-font-weight: normal,
  text-heading-3-font-weight: normal,
  text-heading-4-font-weight: normal,
  text-heading-5-font-weight: normal,
  text-heading-6-font-weight: normal,

  header-background-color: $nrk_0,
  header-text-color: white,
  header-height: 4rem,

  sidebar-background-color: $nrk_0,
  sidebar-text-color: white,
  sidebar-width: 14rem,
  sidebar-header-height: 1rem,
  // sidebar-footer-height: 3rem,

  // menu-text-font-family: 'Nipponrika-Light',
  menu-text-font-weight: 500,
  menu-text-color: white,
  menu-text-font-size: 1.2rem,
  menu-item-padding:  1rem 1.5rem,
  menu-item-hover-background-color: $nrk_2,
  menu-item-hover-text-color: color-primary-900,
  menu-item-divider-color: color-primary-400,
  menu-item-icon-color: white,
  // menu-item-icon-width: 1.5rem,
  menu-item-active-background-color: $nrk_2,
  menu-item-active-text-color: color-primary-800,

  menu-submenu-background-color: white,
  menu-submenu-text-color: #4b4b4b, // not working !! -> tmp fix in pages.component.scss
  menu-submenu-padding: 0,
  menu-submenu-item-padding: 0.4rem 1.7rem,
  menu-submenu-item-hover-background-color: $nrk_2,
  menu-submenu-item-active-background-color: $nrk_2,
  menu-submenu-item-active-text-color: color-primary-800,

  input-basic-disabled-text-color: input-basic-text-color,
  input-small-text-font-weight: layout-text-font-weight,
  input-medium-text-font-weight: layout-text-font-weight,
  input-large-text-font-weight: layout-text-font-weight,
  radio-text-font-weight: layout-text-font-weight,
  radio-text-font-size: text-paragraph-font-size,

  smart-table-header-bg: #eee,
  // smart-table-header-fg: #000,
  // smart-table-header-font-size: 20px,
  smart-table-header-font-weight: bold,
  // smart-table-font-size: 20px,
  smart-table-separator: #cdd,

  popover-border-color: #ddd,
  popover-shadow: rgba(14, 30, 37, 0.4) 0px 2px 16px 0px,
);

// register the themenrk-table
$nb-themes: nb-register-theme($theme, nipponrika, default);



:host ::ng-deep {
  .menu-item a {
    // border-radius: 0;
  };

  .dropdown-inline.open {
    .btn-primary {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  /*.btn-dropdown {
    min-width: 15em;
  }*/

  .dropdown-item.active {
    background-color: white;
  }

  .form-control {
    margin-bottom: 0.25rem !important;
  }

  .form-group {
    margin-bottom: 0 !important;
  }

  .nrk-dropdown-menu {
    margin-left: 0.5rem !important;
    margin-top: -0.5rem !important;

    background-color: rgba(221,221,221,1) !important;
    box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.1);
    width: inherit !important;
    min-width: 25rem !important;
  }

  .ngx-pagination {
    text-align: center;
  }

  .ngx-pagination .current {
    background: #c89712;
  }

  .table {
    margin-top: 1rem;
    td {
      padding: 0.25rem 0.5rem !important;
      vertical-align: middle;
    }
  }

  .top label {
    color: black;
  }

  ng2-smart-table {
    th {
      font-size: 14px !important;
      // padding: 0.5rem 0.5rem !important;
    }
    td {
      font-size: 14px !important;
      // padding: 0.25rem 0.5rem !important;
    }
  }

  .sidebar-fix {
    width: 100%;
    height: 100%;
    padding: 2em;
    min-width: 40em;
    background-color: white;
    box-shadow: 0 0 2em black;
    overflow: auto;
  }

  aside {
    min-width: 100%;
    z-index: 99 !important;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2); // #eeeeee;
  }

  .form-control:focus {
    border-color: #c89712;
  }

  .form-control-sm {
    font-size: 1rem !important;
    padding: 0.25rem 0.5rem !important;
    height: 2rem !important;
    margin-bottom: 0.25rem !important;
  }

  .main-container {
    border-top-right-radius: 0 !important;
  }

  .table th {
    background-color: #eeeeee;
  }

  .btn-default {
    background-color: #EFEFEF;
    border: 1px solid #8887;
  }

  .btn {
    padding: 0.5rem 1rem !important;
    text-transform: uppercase;
  }

  ss-multiselect-dropdown {
    .dropdown-menu {
      border: 1px solid gray;
    }
    .dropdown-item.active, .dropdown-item:active {
      color: black;
      background-color: $nrk_2;
    }
    a > span > span {
      margin-left: 0.5em;
    }
  }


}


.nrk-dropdown-menu {
  min-width: 15em !important;
}
.dropdown-toggle.noarrow {
  text-align: center !important;
  width: auto !important;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}

.dropdown-toggle.noarrow::after {
  content: none;
  display: none;
}


.btn-highlight {
  color: #eeeeee;
  background-color: #c89712;
}

.btn-highlight:hover {
  color: #eeeeee;
  background-color: lighten(#c89712, 5%);
}



.sidebar.header {
  border-bottom: 1px solid #eeeeee;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.2em 0.75em 0.2em 2em;
  h1 {
    line-height: 1;
  }
}

.sidebar.header-spacing {
  margin-top: 5.5rem;
}

.sidebar.bright {
  color: #cccccc;
}

.sidebar.footer {
  box-shadow: 5px 5px #eeeeee;
  border-top: 1px solid #eeeeee;
  background-color: white;
  padding: 0.3rem 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.btn {
  margin: 0.5rem !important;
}

.btn-primary {
  color: #ffffff !important;
}

.btn-success {
  color: #ffffff !important;;
}

.main-content {
  padding: 0 !important;
}

.ng2-smart-action-add-add {
  background-color: $nrk_3 !important;
}

.input-group-append {
  .btn {
    margin: 0 !important;
  }
}
