
$nrk_0: #a70240;
$nrk_1: #dd4814;
$nrk_2: #ffb612;
$nrk_3: #e00034;

.nrk-primary-bg {
  background-color: $nrk_0;
  color: white;
}

.nrk-secondary-bg {
  background-color: $nrk_1;
  color: white;
}

.reset {
  all: initial !important;
  * {
    all: unset !important;
  }
}

.nrk-v-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nrk-border-box {
  border: 1px solid lightgray;
  padding: 0.5em;
  margin: 0.2em;
}

.nrk-table {
  th { background-color:lightgray }
  td, th { padding: 0.3em; }
  border: 1px solid lightgray;
}
.nrk-table-light {
  @extend .nrk-table;
  th { background-color: #ddd8 }
}
.nrk-row-select tr {
  cursor: pointer;
  &:hover {
    background-color: $nrk_2;
  }
  &:active {
    color: white;
  }
}
.nrk-odd tbody:nth-child(even) {
  background-color: rgba(232, 232, 232, 0.6);
}
.nrk-button {
	box-shadow: inset 0px 1px 0px 0px #ffffff;
	background-color: #ededed;
	border-radius: 3px;
	border: 1px solid #9c9c9c;
	display: inline-block;
  cursor: pointer;
  padding: 1px 6px;
  vertical-align: middle;
	text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
  &:hover {
    background-color:#dfdfdf;
  }
  &:active {
    position:relative;
    top:1px;  
  }
  &:disabled{
    &:active{
      top:0;
    }
    cursor: default;
    opacity:0.5;
  }
}
.nrk-doc-button {
  @extend .nrk-button;
  text-align: center;
  width: 22px;
  height: 22px;
}
.nrk-disabled {
  opacity: 0.5;
}
.nrk-disabled-background {
  background-color: rgba(220, 220, 220, 0.5) !important;
  // border-color: rgba(220, 220, 220, 0.5) !important;
  // border-color: transparent !important;
  border: transparent !important;
  // color: rgb(120,120,120) !important;
  // color: black !important;
}
.nrk-row-disabled {
  // background-color: rgba(179, 179, 179, 0.575);
  // opacity: 0.4;
  color: rgba(0, 0, 0, 0.5)
}
.nrk-draft {
  background-color: #eec;
}
.nrk-smaller-font {
  font-size: 0.9rem;
}
.pseudo-link {
  color: #bd0221;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.pseudo-unlink {
  color: black !important;
  &:hover {
    text-decoration: none;
  }
}
.nrk-nrka-menu-background, .nrk-nrka-menu-background a {
  // background-color: #aaffff;
  background-color: #a7f1e9;
}
.nrk-nrkg-menu-background, .nrk-nrkg-menu-background a {
  // background-color: #ffffaa;
  // background-color: #ffc966;
  background-color: #ffe087;
}
.nrk-nrka-background {
  // background-color: #ddffff !important;
  background-color: #d3f8f4 !important;
}
.nrk-nrkg-background {
  // background-color: #ffffdd !important;
  background-color: #ffedcc !important;
  // background-color: #fff8cc !important;
}
.nrk-pointer {
  cursor: pointer;
}

.nrk-wizard-footer {
  position: absolute;
  bottom: 0;
  border-top: 1px solid lightgray;
  left: 0;
  right: 0;
  background-color: white;
  margin-bottom: 3px;
}

.nrk-sticky-header th {
  position: sticky;
  top: 0;
  z-index: 1;
  opacity: 1;
}
